<template>
  <footer data-aos="fade-up" class="footer">
    <div class="container">
      <div class="footer__top">
        <div class="row flex-lg-row-reverse row-gap-30">
          <div class="col-lg-2">
            <div class="footer-menu__wrapper">
              <ul class="footer-menu">
                <li>
                  <router-link
                    :to="$localePath({ name: 'Markets' })"
                    class="footer-menu__item"
                  >
                    {{ $t("menu.markets") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Platform' })"
                    class="footer-menu__item"
                  >
                    {{ $t("menu.platform") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'About' })"
                    class="footer-menu__item"
                  >
                    {{ $t("menu.about") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Contacts' })"
                    class="footer-menu__item"
                  >
                    {{ $t("menu.contacts") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="mb-30">
              <router-link
                :to="$localePath({ name: 'Home' })"
                class="footer__logo"
              >
                <img :src="require('@/assets/img/logo.svg')" alt="" />
              </router-link>
            </div>
            <p class="text-small" v-html="$t('textBlock')"></p>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <p>© 2024 ABM Trades</p>
        <p>
          {{ $t("support") }}:
          <a class="color-gray2" href="mailto:support@abmtrades.com">
            support@abmtrades.com
          </a>
        </p>
        <p>
          {{ $t("support") }}:
          <a class="color-gray2" href="tg://resolve?domain=abmtradesx">
            Telegram
          </a>
        </p>
        <router-link :to="$localePath({ name: 'Privacy' })" class="color-body">
          {{ $t("privacy") }}
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  i18n: {
    messages: {
      en: {
        textBlock:
          "<b>Risk Warning:</b> ABM Trades specialises in providing a wide range of investment and brokerage services. Before embarking on an investment, it is important to recognise the potential financial risks involved, it is recommended that you carefully study the features of each instrument. It is important to note that the information provided on this website does not constitute personalised financial advice. We recommend consulting an independent financial analyst. And past performance does not guarantee future success, and trading is always associated with risks. Our company strongly recommends diversifying your investment portfolio to reduce risks and paying attention to the current economic and geopolitical situation.",
      },
      ru: {
        textBlock:
          "<b>Предупреждение о рисках:</b> Компания ABM Trades специализируется на предоставлении широкого спектра услуг в сфере инвестиций и брокерского обслуживания. Прежде чем приступить к инвестированию, важно осознать потенциальные финансовые риски, с которыми оно связано, рекомендуется внимательно изучить особенности каждого инструмента. Важно отметить, что информация, предоставленная на данном сайте, не является индивидуальным финансовым советом. Мы рекомендуем обратиться к независимому финансовому аналитику. А прошлые результаты не гарантируют будущие успехи, и торговля всегда связана с рисками. Наша компания настоятельно рекомендует диверсифицировать инвестиционный портфель для снижения рисков и обращать внимание на текущую экономическую и геополитическую ситуацию.",
      },
      fr: {
        textBlock:
          "<b>Avertissement de risque:</b> ABM Trades est spécialisé dans la fourniture d'une large gamme de services d'investissement et de courtage. Avant de se lancer dans un investissement, il est important de reconnaître les risques financiers potentiels encourus, il est recommandé d'étudier attentivement les caractéristiques de chaque instrument. Il est important de noter que les informations fournies sur ce site ne constituent pas un conseil financier personnalisé. Nous recommandons de consulter un analyste financier indépendant. De plus, les performances passées ne garantissent pas les succès futurs et le trading est toujours associé à des risques. Notre société recommande vivement de diversifier votre portefeuille d'investissement afin de réduire les risques et de prêter attention à la situation économique et géopolitique actuelle.",
      },
      de: {
        textBlock:
          "<b>Risiko-Warnung:</b> ABM Trades ist auf eine breite Palette von Anlage- und Vermittlungsdienstleistungen spezialisiert. Bevor Sie eine Anlage tätigen, sollten Sie sich über die potenziellen finanziellen Risiken im Klaren sein. Es wird empfohlen, die Merkmale der einzelnen Instrumente sorgfältig zu prüfen. Wir weisen darauf hin, dass die auf dieser Website bereitgestellten Informationen keine persönliche Finanzberatung darstellen. Wir empfehlen, einen unabhängigen Finanzanalysten zu konsultieren. Die Performance in der Vergangenheit ist keine Garantie für zukünftigen Erfolg, und der Handel ist immer mit Risiken verbunden. Unser Unternehmen empfiehlt dringend, Ihr Anlageportfolio zu diversifizieren, um die Risiken zu verringern, und die aktuelle wirtschaftliche und geopolitische Lage zu berücksichtigen.",
      },
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  background: $white;
}
.footer__logo {
  display: block;
  width: 120px;
}
.footer__top {
  padding: 60px 0;
}

.footer__bottom {
  border-top: 1px solid rgba($gray2, 0.3);
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  color: $gray2;

  .color-body {
    text-decoration: underline;
  }
}

.footer-menu__wrapper {
  text-align: right;
}

.footer-menu {
  font-weight: 700;
  display: inline-flex;
  flex-direction: column;
  gap: 25px;
  text-align: left;
}

.footer-menu__item {
  color: $body-color;

  &:hover {
    color: $primary-color;
  }
}

@include media-breakpoint-down(lg) {
  .footer-menu__wrapper {
    text-align: left;
  }
  .footer-menu {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
</style>
