<template>
  <div>
    <AppHeader />
    <RouterView />
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "@/components/app/AppFooter.vue";
import AppHeader from "@/components/app/AppHeader.vue";
import Aos from "aos/src/js/aos";

export default {
  name: "BaseLayout",
  components: { AppHeader, AppFooter },
  mounted() {
    Aos.init();
  },
};
</script>

<style scoped></style>
