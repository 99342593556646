import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import en from "./lang/en";
import ru from "./lang/ru";
import fr from "./lang/fr";
import de from "./lang/de";

import VueI18n from "vue-i18n";
import VueMeta from "vue-meta";
import { Vuelidate } from "vuelidate";

import "./assets/scss/global.scss";

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(Vuelidate);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: ["en"],
  silentTranslationWarn: true,
  dateTimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
      mTime: {
        hour: "numeric",
        minute: "numeric",
      },
    },
    ru: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
      mTime: {
        hour: "numeric",
        minute: "numeric",
      },
    },
  },
  messages: {
    en,
    ru,
    fr,
    de,
  },
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
