<template>
  <div class="section-auth">
    <section class="section-lg">
      <router-view />
    </section>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style scoped></style>
